<template>
	<div>
		<v-card
			color="#feeea4"
			variant="tonal"
			max-width="100%"
			class="mx-auto px-2 py-2"
			elevation="0"
		>
			<v-card-text class="pa-0 ma-0">
				<v-row>
					<v-col class="text-h2 pr-0" cols="2">
						<v-img
							:src="require('@/assets/infobox_check.svg')"
							width="33px"
							top
							center
							contain
							style="margin: 0 auto"
							v-if="ico == `check`"
						/>
						<v-img
							:src="require('@/assets/infobox_home.svg')"
							width="33px"
							top
							center
							contain
							style="margin: 0 auto"
							v-if="ico == `home`"
						/>
					</v-col>
					<v-col cols="10" class="pl-0">
						<p
							class="text-justify font-weight-bold pb-0 mb-0"
							style="font: normal normal bold 20px/27px Nunito"
						>
							<slot name="heading"> </slot>
						</p>
						<p
							style="
								line-height: 1.3;
								font: normal normal normal 12px/16px Nunito;
								letter-spacing: 0.36px;
							"
						>
							<slot name="content"> </slot>
						</p>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</div>
</template>
<script>
export default {
	props: {
		ico: {
			type: String,
			default: "check",
		},
	},
};
</script>
